<template>
  <div>
    <aRow :gutter="20">
      <aCol class="mb-20" :span="24">
        <aDivider class="mt-0" orientation="left">
          <font class="f11">Empresas / Filiais</font>
        </aDivider>
        <aCheckbox
          class="mb-20"
          v-model="tempDocument.is_general"
          style="font-size: 12px; font-weight: 500; position: relative"
        >
          Marque se este documento é para todas empresas
        </aCheckbox>
      </aCol>

      <aCol v-show="!tempDocument.is_general" :span="12">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Empresa </label>

          <a-select
            class="travel-input"
            placeholder="Selecione uma empresa"
            optionFilterProp="txt"
            @change="getCompanyBranchesOnChange"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} -
              {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol v-show="!tempDocument.is_general" :span="12">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Filial </label>

          <a-select
            class="travel-input"
            placeholder="Selecione uma filial"
            optionFilterProp="txt"
            :disabled="companyBranchesList.length == 0"
            v-decorator="[
              `company_branch_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of companyBranchesList"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} -
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol class="mb-20" :span="24">
        <aDivider class="mt-0" orientation="left">
          <font class="f11">Contrato</font>
        </aDivider>
      </aCol>

      <aCol :span="12">
        <HayaTextField label="ID contrato (opcional)" fieldKey="contract_id" />
      </aCol>

      <aCol :span="24">
        <aDivider orientation="left">
          <font class="f11">Documentos Repetidores</font>
        </aDivider>
        <aAlert
          class="f11 mb-20"
          message="Documentos repetidores são aqueles que contêm páginas com informações idênticas. Selecione a opção a seguir para ativá-lo."
          type="info"
          banner
        />
      </aCol>

      <aCol class="mb-20" :span="12">
        <a-checkbox
          class="mt-10"
          v-model="tempDocument.is_repeater"
          style="
            font-size: 12px;
            font-weight: 500;
            position: relative;
            margin-bottom: 18px;
          "
          @change="onChangeIsRepeater"
        >
          Documento repetidor
        </a-checkbox>
      </aCol>

      <aCol v-show="tempDocument.is_repeater" :span="12">
        <HayaSelectField
          label="Tipo de repetidor"
          fieldKey="repeater_type"
          :list="[
            {
              label: 'Cartão de Crédito (Contrato)',
              value: 'credit-card-contract',
            },
            { label: 'Terceiros (Contrato)', value: 'third-party-contract' },
          ]"
        />
      </aCol>
    </aRow>
  </div>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";

export default {
  name: "DocumentEditModal",
  props: {
    docForm: Object,
    tempDocument: Object,
  },
  components: { HayaTextField, HayaSelectField },
  data() {
    return {
      companiesList: [],
      companyBranchesList: [],
    };
  },
  mounted() {
    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    if (this.tempDocument.company_id != 0)
      this.getCompanyBranches(this.tempDocument.company_id);

    this.docForm.setFieldsValue({
      company_id:
        this.tempDocument.company_id != 0
          ? this.tempDocument.company_id
          : undefined,
    });

    setTimeout(() => {
      this.docForm.setFieldsValue({
        repeater_type: this.tempDocument.repeater_type,
        contract_id: this.tempDocument.contract_id,
        company_branch_id:
          this.tempDocument.company_branch_id != 0
            ? this.tempDocument.company_branch_id
            : undefined,
      });
    }, 20);
  },
  methods: {
    onChangeIsRepeater() {
      this.$emit("onChangeIsRepeater");
    },
    getCompanyBranchesOnChange(id) {
      this.docForm.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
  },
};
</script>
