<template>
  <section class="top-bar">
    <aRow type="flex" justify="space-between">
      <aCol class="">
        <aRow type="flex" justify="start" :gutter="20">
          <aCol>
            <a-icon
              class="menu"
              type="menu"
              @click="openDocumentInfosDrawer = true"
          /></aCol>
          <aCol>
            <a-form-item class="model-name">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                placeholder="NOME DO MODELO"
              >
              </a-input> </a-form-item
          ></aCol>

          <!-- <aCol class="undo-redo">
            <a-tooltip>
              <template slot="title"> Desfazer </template>
              <a
                class="undo option"
                @click="undo"
                :disabled="[0, 1].includes(undoStack.length) ? true : false"
                ><a-icon type="arrow-left" />
              </a>
            </a-tooltip>
            <a-tooltip>
              <template slot="title"> Refazer </template>
              <a
                class="redo option"
                @click="redo"
                :disabled="redoStack.length === 0"
                ><a-icon type="arrow-right" />
              </a>
            </a-tooltip>
            <a-divider type="vertical" />
          </aCol> -->

          <aCol>
            <a-tooltip v-if="!previewDoc && docStructure.length" class="prev">
              <template slot="title"> Prever documento </template>
              <a-icon type="eye" @click="onClickPrevDoc(true)" />
            </a-tooltip>

            <a-tooltip v-if="previewDoc">
              <template slot="title"> Editar documento </template>
              <a-icon class="prev" type="edit" @click="onClickPrevDoc(false)" />
            </a-tooltip>
          </aCol>

          <aCol>
            <a-icon
              v-if="previewDoc && !generatePDFLoading"
              class="pdf"
              type="file-pdf"
              @click="onClickGeneratePdf"
            />

            <a-icon v-if="generatePDFLoading" class="pdf" type="loading" />
          </aCol>
        </aRow>
      </aCol>
      <aCol class="">
        <span class="status">
          <aCheckbox class="mb-20" v-model="tempDocument.status" style="">
            {{ tempDocument.status ? "Ativo" : "Desativado" }}
          </aCheckbox>
        </span>
        <a-button
          html-type="submit"
          :loading="loadingUpdateButton"
          type="primary"
          >Atualizar</a-button
        >
      </aCol>
    </aRow>

    <aDrawer
      placement="left"
      :closable="true"
      :width="$root.windowWidth > 960 ? '600px' : '100%'"
      :visible="openDocumentInfosDrawer"
      @close="onCloseDocumentInfosDrawer"
    >
      <template #title>
        <a-icon type="file-text" class="mr-5 cprimary" /> DADOS DO DOCUMENTO
      </template>
      <DocumentEditModal
        v-if="openDocumentInfosDrawer"
        :docForm="docForm"
        :document="document.details"
        :tempDocument="tempDocument"
        @onChangeIsRepeater="onChangeIsRepeater"
      />
    </aDrawer>

    <aModal
      title="Verificação"
      :visible="openPasswordModal"
      :footer="null"
      @cancel="openPasswordModal = false"
      width="300px"
    >
      <aRow style="margin-top: -20px">
        <aCol :span="24">
          <input
            name="email"
            style="
              color: #fff;
              opacity: 0;
              pointer-events: none;
              height: 0;
              padding: 0;
              border: 0;
            "
            placeholder="Fake email fix"
          />
          <a-input
            class="travel-input"
            v-model="userPassword"
            type="password"
            placeholder="Insira sua senha"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </aCol>
        <aCol :span="24">
          <input
            name="email"
            style="
              color: #fff;
              opacity: 0;
              pointer-events: none;
              height: 0;
              padding: 0;
              border: 0;
            "
            placeholder="Fake email fix"
          />
          <form>
            <a-input
              class="travel-input"
              name="auth-code"
              v-model="authCode"
              placeholder="Código de autorização"
            >
              <a-icon
                slot="prefix"
                type="idcard"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </form>
        </aCol>
        <aCol class="a-center" :span="24">
          <a-button
            @click="getCardData"
            class="mt-30"
            type="primary"
            :loading="getCardDataLoading"
          >
            Ver dados dos cartões
          </a-button>
        </aCol>
      </aRow>
    </aModal>
  </section>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import DocumentEditModal from "../modals/DocumentEditModal.vue";

export default {
  name: "DocPageTopBar",
  props: {
    undoStack: Array,
    redoStack: Array,
    tempDocument: Object,
    previewDoc: Boolean,
    loadingUpdateButton: Boolean,
    docForm: Object,
    document: Object,
    contract: Object,
    docStructure: Array,
  },
  components: { DocumentEditModal },
  data() {
    return {
      openDocumentInfosDrawer: false,
      generatePDFLoading: false,
      openPasswordModal: false,
      userPassword: undefined,
      authCode: undefined,
      getCardDataLoading: false,
      creditCardDataIsShown: false,
    };
  },
  mounted() {},
  methods: {
    onChangeIsRepeater() {
      this.$emit("onChangeIsRepeater");
    },
    onCloseDocumentInfosDrawer() {
      this.openDocumentInfosDrawer = false;
      this.$emit("onCloseDocumentInfosDrawer");
    },
    undo() {
      this.$emit("undo");
    },
    redo() {
      this.$emit("redo");
    },
    onClickPrevDoc(value) {
      this.$emit("onClickPrevDoc", value);
    },
    onClickGeneratePdf() {
      const documentsWrapper = document.getElementById("print-area");
      const outerHTMLContent = documentsWrapper.outerHTML;
      this.generatePDFLoading = true;

      axios
        .post(
          `https://integrations.haya.net.br/v1/integrations/generate-pdf`,
          {
            html: btoa(
              unescape(
                encodeURIComponent(
                  outerHTMLContent
                    .replace("v-style", "style")
                    .replace("v-style", "style")
                )
              )
            ),
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then(({ data }) => {
          // Create a Blob from the response data
          const blob = new Blob([data], { type: "application/pdf" });

          // Create a link element
          const link = document.createElement("a");

          // Set the href attribute to a Blob URL representing the data
          link.href = window.URL.createObjectURL(blob);

          if (this.contract.payment_methods.includes("credit-card")) {
            if (this.creditCardDataIsShown) {
              // Specify the filename for the download
              link.download = `${this.tempDocument.name}.pdf`;

              // Append the link to the document
              document.body.appendChild(link);

              // Trigger the click event to start the download
              link.dispatchEvent(new MouseEvent("click"));

              // Remove the link from the document
              document.body.removeChild(link);

              this.generatePDFLoading = false;
            } else {
              this.$message.warning(
                "Você está gerando o PDF sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
              );
              this.openPasswordModal = true;
              this.generatePDFLoading = false;
            }
          } else {
            link.download = `${this.tempDocument.name}.pdf`;
            document.body.appendChild(link);
            link.dispatchEvent(new MouseEvent("click"));
            document.body.removeChild(link);
            this.generatePDFLoading = false;
          }
        });
    },
    generatePDF(docName) {
      this.generatePDFLoading = true;

      const element = document.getElementById("print-area");
      const options = {
        filename: docName,
        image: { type: "jpeg", quality: 0.3 },
        enableLinks: false,
        margin: [0, 0, 0, 0],
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      if (this.contract.payment_methods.includes("credit-card")) {
        if (this.creditCardDataIsShown) {
          html2pdf().from(element).set(options).save();
          this.generatePDFLoading = false;
        } else {
          this.$message.warning(
            "Você está gerando o PDF sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
          );
          this.openPasswordModal = true;
        }
      } else {
        html2pdf().from(element).set(options).save();
        this.generatePDFLoading = false;
      }
    },
    getCardData() {
      if (this.userPassword && this.authCode) {
        this.getCardDataLoading = true;
        this.$http
          .post("/contract/read_card", {
            email: this.$store.state.userData.email,
            password: this.userPassword,
            auth_code: this.authCode,
            contract_id: this.contract.id,
            card_id: 1,
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            this.contract.meta = {
              ...this.contract.meta,
              ...data.card,
            };

            this.creditCardDataIsShown = true;
            this.openPasswordModal = false;
            this.authCode = undefined;
            this.getCardDataLoading = false;
            this.onClickGeneratePdf();
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.openPasswordModal = false;
            this.getCardDataLoading = false;
          });
      } else {
        this.$message.error("Insira sua senha e o código de autorização.");
      }
    },
    printDocs(docName) {
      docName;
      window.print();
    },
  },
};
</script>

<style lang="sass" scoped>
.top-bar
  top: 60px
  position: fixed
  z-index: 15
  box-shadow: 0 0 40px rgba(0,0,0,.2)
  padding: 10px
  background: #fff
  right: 5px
  left: 101px
  .undo-redo
    font-size: 24px
    position: relative
    top: 2px
    .undo
      margin-right: 15px
  .pdf
    position: relative
    top: 10px
    font-size: 22px
    color: #E74C3C
  .prev
    position: relative
    top: 9px
    font-size: 24px
    color: #999
  .menu
    position: relative
    top: 6px
    font-size: 28px
    color: #bbb
    margin-left: 10px
  .ant-form-item
    margin: 0
  .contract-id
    input
      border: 0
      width: 120px
      background: #eee
  .model-name
    input
      border: 0
      width: 600px
      background: #eee
  .status
    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
      border: 0
      color: #be4178
    .ant-checkbox-wrapper
      border: 0
      padding: 10px
      margin: 0 10px 0 0 !important
</style>
